@import './includes.scss';

.DateInput {
    display: none;
}

.DateInput_input {
    display: none;
}

.DateRangePickerInput_arrow {
    display: none;
}

.DateRangePicker_picker {
    display: block;
    @include media(l) {
        display: block;
    }
}

.DayPicker {
    max-width: 29.6rem;
    min-height: 33.4rem;
    > div {
        height: 100%;
    }

    svg {
        fill: $colorWhite;

        @include media(l) {
            fill: $colorGray500;
        }
    }
}

.DayPicker_transitionContainer {
    padding-bottom: 74px;
    box-sizing: content-box;
}

.DateRangePicker {
    margin-top: 18px;
    margin-left: -28px;
    min-height: 300px;

    div {
        background: $colorBlack800;
        color: $colorWhite;
        border: none !important;
        box-shadow: none !important;
        @include media(l) {
            background: $colorWhiteBackground;
            color: $colorBlack800;
        }
    }
}

.DateRangePickerInput {
    background-color: $colorBlack800 !important;
    border: none !important;

    @include media(l) {
        background-color: $colorWhiteBackground;
    }
}

.CalendarMonth_table {
    margin-top: 15px;
}

.CalendarMonth_caption {
    text-transform: uppercase;
    letter-spacing: 0.263rem;
    font-size: 1.4rem !important;
    color: $colorWhite !important;

    @include media(l) {
        color: $colorBlack800 !important;
    }
}

.CalendarDay {
    border: none !important;
    color: $colorWhite;
    width: 44px;
    height: 40px;
    font-size: 1.6rem;
    line-height: 40px;
    background-color: $colorBlack800 !important;

    @include media(l) {
        color: $colorGray500;
        width: 44px;
        height: 40px;
        background-color: $colorWhiteBackground !important;
    }

    &[aria-disabled='true'] {
        color: $colorGray200;
    }
}

.DayPicker_weekHeader_li {
    font-size: 1.9rem;
    font-weight: bold;
    line-height: 1.9rem;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
    background-color: rgba(232, 0, 97, 0.25) !important;
    color: $colorWhite;
    border: none !important;

    @include media(l) {
        color: $colorGray500;
    }

    &:hover {
        background: $colorPink !important;
    }
}

.CalendarDay__default {
    color: #fff;

    @include media(l) {
        color: $colorGray500;
    }

    &:hover {
        border: none !important;
        background: $colorPink !important;
        color: $colorWhite;
    }
}

.CalendarDay__selected {
    background: $colorPink !important;
    color: $colorWhite;
    border-radius: 0; //2px;

    &_start_in_hovered_span {
        //background: $colorPink;
    }

    :hover {
        background: $colorPink !important;
    }
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
    background-color: rgba(232, 0, 97, 0.25) !important;
    @include media(l) {
        color: $colorGray500 !important;
    }
}

.CalendarDay__selected_start,
.CalendarDay__selected_end {
    border-radius: 0; //2px;
    :hover {
        background: $colorPink !important;
    }
}

.CalendarDay__selected_start_in_hovered_span.CalendarDay__selected_start_no_selected_end {
    @include media(l) {
        //color: $colorGray500;
    }
}

.DayPicker_weekHeader_ul {
    color: $colorWhite;
    font-weight: bold;
    background: transparent;

    @include media(l) {
        color: $colorBlack800;
    }
}

.DayPickerNavigation_button__default {
    border: none !important;
}

.DateRangePicker_picker {
    display: block;
    background: $colorBlack800;
    position: static;

    @include media(l) {
        display: block;
        background: $colorGray10;
    }
}

.DatePicker__ButtonGroup--Start {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.DatePicker__ButtonGroup--End {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.DatePicker__ToggleTitle,
.FilterControls__Title {
    margin-left: 10px;
}

.DatePicker__Calendar {
    background-color: $colorBlack800;
    display: none;
    width: 295px;
    margin-left: -2.8rem;

    @include media(l) {
        display: flex;
        background-color: $colorGray10;
    }

    &--Expanded {
        background-color: $colorBlack800;
        display: block;
        z-index: 100000;
        @include media(l) {
            background-color: $colorGray10;
        }

        .DateRangePicker_picker {
            display: block !important;
            background: $colorBlack800;
            position: static !important;

            @include media(l) {
                display: block;
                background-color: $colorGray10;
            }
        }
    }
}

.CalendarDay__selected_start_in_hovered_span {
    // background: $colorPink;
}

.CalendarDay__selected_start {
    background-color: $colorPink !important;
}

.CalendarDay__selected_span {
    background-color: rgba(232, 0, 97, 0.25) !important;
}

.CalendarMonth,
.DayPicker_transitionContainer,
.CalendarMonthGrid,
.DayPicker__horizontal,
.DayPickerNavigation_button__default {
    background-color: $colorBlack800 !important;

    @include media(l) {
        background-color: $colorGray10 !important;
    }
}

.DayPicker__hidden {
    visibility: visible !important;
}

.DayPicker__horizontal {
    margin-left: -1.4rem;

    @include media(l) {
        margin-left: 0;
    }
}
