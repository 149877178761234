@import './includes.scss';

.leaflet-container {
    width: 100%;
    min-height: 90rem;
}

.leaflet-tooltip {
    color: $colorWhite;
    border: none;
    background: transparent;
}

.leaflet-tile-pane {
    filter: grayscale(100%);
    opacity: 0.7;
}

.marker-cluster.leaflet-marker-icon {
    color: $colorBlack800;
    background-color: $colorWhite;
    font-family: $fontBrand;
    font-size: 1.4rem;
    width: 42px !important;
    height: 42px !important;

    & div {
        background-color: $colorPink300;
        width: 38px;
        height: 38px;
        border-radius: 20px;
        margin-left: 2px;
        margin-top: 2px;
    }
    & span {
        font-family: $fontBrand;
        font-size: 1.4rem;
        line-height: 40px;
    }

}

.leaflet-control-container {
    position: absolute;
    right: 5rem;
    top: 82rem;
}
